const urls = {
  revenues: '/app/revenues',
  transactions: {
    all: '/app/transactions',
    rakuten: '/app/transactions/rakuten',
    impact: '/app/transactions/impact',
    cj: '/app/transactions/cj',
    partnerize: '/app/transactions/partnerize',
    shareASale: '/app/transactions/shareASale',
    pepperjam: '/app/transactions/pepperjam',
    awin: '/app/transactions/awin',
    ebay: '/app/transactions/ebay',
  },
  members: {
    all: '/app/members',
  },
  payments: {
    all: '/app/payments/cashback',
    toPay: '/app/payments/cashback-to-pay',
    referrals: '/app/payments/referral-bonus',
    referralsToPay: '/app/payments/referral-bonus-to-pay',
    pendingReferralsPayments: '/app/payments/pending-referral-bonus',
    proShareCommissions: {
      all: '/app/paid-shared-commissions',
      toPay: '/app/to-pay-shared-commissions',
    },
  },
  brands: {
    all: '/app/brands/all',
    topPerforming: '/app/brands/top',
    newBrands: '/app/brands/new',
    brandsChangelog: '/app/brands/changelog',
    bulkUpdateBrandUserCommission: '/app/brands/bulk-update-user-commission',
  },
  settings: {
    all: '/app/settings',
    joinPages: '/app/join-pages',
  },
  admins: {
    all: '/app/admins',
  },
  exportLogs: {
    all: '/app/export-logs',
  },
  createShareLink: '/app/share-with-friends',
  subscription: '/app/subscription',
  topBanner: '/app/banner',
  topBannerSafari: '/app/banner/safari',
  topBannerChrome: '/app/banner/chrome',
  cms: '/app/cms',
  categories: '/app/categories',
  productCatalogues: '/app/product-catalogues',
  trending: '/app/trending-products',
  topProducts: '/app/top-products',
  topCategoryProducts: '/app/top-category-products',
  productParserLogs: '/app/product-parser-logs',
  paymentHeldLogs: '/app/payment-held-logs',
  editPages: '/app/edit-pages',
  shortLinks: '/app/short-links',
  dashboard: {
    analytics: '/app/dashboard/analytics',
  },
  revShare: '/app/rev-share',
};

export default urls;
